
class Utils {
    static shuffle = (array) => {
        for (let a=array.length - 1; a > 0; a--) {
          const b = Math.floor(Math.random() * (a+1))
          const temp = array[a]
          array[a] = array[b]
          array[b] = temp
        }
    }
    
    static getIndexesMedium(size, count) {
        let to_choose = []
    
        while (to_choose.length < count) {
          const random_index = Math.floor(Math.random() * size);
          if (! to_choose.includes(random_index)) {
            to_choose.push(random_index);
          }
        }
    
        return to_choose
    }
      
}

export default Utils;
